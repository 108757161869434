/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { OnlineMeetingEntry } from '../../../Model/Explore/OnlineMeetingEntry';
import styled from '@emotion/styled';
import { useRootStore } from '../../../Store/useRootStore';
import ReactPlayer from 'react-player';
import { logger } from '../../../Utils/logger';
import { ArticleEntry } from '../../../Model/Explore/ArticleEntry';
import { VideoEntry } from '../../../Model/Explore/VideoEntry';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 16px;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  height: 300px;
  border-radius: 16px 16px 0px 0px;
  margin-bottom: 16px;

  @media (min-width: 600px) {
    height: 360px;
    margin-bottom: 32px;
  }

  @media (min-width: 992px) {
    height: 480px;
  }

  @media (min-width: 1280px) {
    height: 600px;
  }

  video {
    object-fit: contain;
  }
`;

const VideoContainer = styled.div`
  //position: absolute;
  //top: 0;
  //right: 0;
  //bottom: 0;
  //left: 0;
  //
  video {
    object-fit: contain;
  }
`;

const VideoPlayer = styled(ReactPlayer)``;

export type ExploreContentViewArticleTeaserProps = {
  entry: VideoEntry;
};

export const ExploreContentViewVideoPlayer: React.FC<ExploreContentViewArticleTeaserProps> = observer(({ entry }) => {
  const { language } = useRootStore();

  const video = entry.getVideo(language.language)?.url;
  const image = entry.getImage(language.language)?.largeOrMediumOrSmallest;

  if (video) {
    return (
      <Container>
        <VideoPlayer
          playsinline={true}
          url={video}
          width="100%"
          height="100%"
          muted={true}
          controls={true}
          playing={true}
          loop={true}
          style={{ objectFit: 'cover' }}
        />
      </Container>
    );
  }

  return null;
});
