/**
 * Created by neo on 22.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../Store/useRootStore';
import { OnlineMeetingEntry } from '../../Model/Explore/OnlineMeetingEntry';
import { ExploreEntryContainer, ExploreEntryContainerProps } from './ExploreEntryContainer';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useTrans } from '../../Store/System/LocalizationStore';
import { ExploreCardTitle } from './ExploreCardTitle';
import { ExploreCardShortDescription } from './ExploreCardShortDescription';
import { LiveTag } from '../LiveTag';
import { WebinarEntry } from '../../Model/Explore/WebinarEntry';

const Tag = styled.div`
  background-color: #f6f6f7;
  color: black;
  font-weight: 700;
  font-size: 14px;
  border-radius: 16px;
  gap: 9.11px;
  padding: 4px 8px;

  &:not(:first-child) {
    margin-left: 8px;
  }

  @media (min-width: 1280px) {
    font-size: 16px;
  }
`;

const TopContentRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DateContainer = styled.div`
  color: black;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Date = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #edeef0;
  border-radius: 8px;
  padding: 6px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
  width: 96px;
`;

const Month = styled.div`
  font-weight: 500;
  font-size: 14px;
`;

const Day = styled.div`
  font-size: 22px;
`;

export type ExploreEntryRendererOnlineMeetingProps = ExploreEntryContainerProps & {
  entry: OnlineMeetingEntry | WebinarEntry;
};

export const ExploreEntryRendererOnlineMeeting: React.FC<ExploreEntryRendererOnlineMeetingProps> = observer(
  ({ entry, ...moreProps }) => {
    const { t } = useTrans();

    const language = entry.language;
    const nextMeetingDate = entry.nextDate;

    return (
      <ExploreEntryContainer entry={entry} {...moreProps}>
        <TopContentRow>
          {language ? <Tag>{language.toUpperCase()}</Tag> : <div />}
          <LiveTag entry={entry} />
        </TopContentRow>
        {nextMeetingDate && (
          <DateContainer>
            <Date style={{ marginTop: 8 }}>
              <Month>{nextMeetingDate.format('ddd')}</Month>
              <Day>{nextMeetingDate.format('DD')}</Day>
              <Month>{nextMeetingDate.format('MMM')}</Month>
            </Date>
            <Date>{nextMeetingDate.format('LT')}</Date>
          </DateContainer>
        )}
      </ExploreEntryContainer>
    );
  },
);
