/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { OnlineMeetingEntry } from '../../Model/Explore/OnlineMeetingEntry';
import styled from '@emotion/styled';
import { useRootStore } from '../../Store/useRootStore';
import { Instructor } from '../../Model/Explore/Instructor';
import { MarkdownRenderer } from '../Markdown/MarkdownRenderer';

const Avatar = styled.img`
  width: 128px;
  height: 128px;
  border-radius: 100%;
  object-fit: cover;
  margin-bottom: 16px;

  @media (min-width: 600px) {
  }
`;

const Name = styled.div`
  font-weight: 700;
  text-align: center;
`;

const Description = styled.div`
  margin-top: 8px;
`;

const TextContainer = styled.div``;

const Container = styled.div`
  background-color: #f6f6f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
  padding: 16px;
  border-radius: 16px;
`;

export type ExploreContentViewHostCardProps = {
  host?: Instructor;
};

export const ExploreContentViewHostCard: React.FC<ExploreContentViewHostCardProps> = observer(
  ({ host }) => {
    const { language } = useRootStore();

    const description = host?.getDescription(language.language);
    const hostName = host?.name;
    const avatarUrl = host?.image?.mediumOrSmallest;

    return (
      <Container>
        <Avatar src={avatarUrl} />
        <TextContainer>
          <Name>{hostName}</Name>
          <Description>
            <MarkdownRenderer text={description} />
          </Description>
        </TextContainer>
      </Container>
    );
  },
);
