/**
 * Created by neo on 02.03.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useRootStore } from '../../../Store/useRootStore';
import { ExploreEntry } from '../../../Model/Explore/ExploreEntry';
import { ExploreEntryBuilder } from '../../../Model/Explore/ExploreEntryBuilder';
import { Col, Container, Row } from 'reactstrap';
import { ExploreCardEntryRenderer } from '../../../Components/ExploreEntryCard/ExploreCardEntryRenderer';
import { PageResult } from '../../../Model/PageResult';
import { Button } from '../../../Components/Button';
import { useTrans } from '../../../Store/System/LocalizationStore';
import styled from '@emotion/styled';
import { Translation } from '../../../Components/Translation';

const Header = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  line-height: 3rem;
`;

const Description = styled.p`
  margin-top: 16px;
`;

export type MoveOndemandLibraryScreenProps = {};

export const MoveOndemandLibraryScreen: React.FC<MoveOndemandLibraryScreenProps> = observer((props) => {
  const { language } = useRootStore();

  const { t } = useTrans();

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(new PageResult<ExploreEntry>());
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (page === 0) {
      setResult(new PageResult());
    }
    setLoading(true);
    PageResult.execute(
      ExploreEntryBuilder.find({
        rootCategories: ['movement'],
        type: ['video', 'article', 'activityWorkout', 'yoga'],
        size: 16,
        sort: 'createdAt,DESC',
        page,
      }),
      ExploreEntry.count({
        rootCategories: ['movement'],
        type: ['video', 'article', 'activityWorkout', 'yoga'],
      }),
      page,
      16,
    )
      .then((res) => setResult((prev) => prev.merge(res)))
      .finally(() => setLoading(false));
  }, [page]);

  const handleLoadMore = React.useCallback(() => {
    setPage((p) => p + 1);
  }, []);

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col xs={12}>
            <Header>
              <Translation
                i18nKey="moveLibrary.title"
                defaultValue={`On Demand\n<span style="color:#24dca4">Library</span>`}
              />
            </Header>
          </Col>
          <Col md={7}>
            <Description>
              <Translation
                i18nKey="moveLibrary.description"
                defaultValue={`Missed a live training? No worries. You can find a selection of old live workouts in our on demand library.`}
              />
            </Description>
          </Col>
        </Row>
        <Row>
          {result.content.map((entry) => (
            <ExploreCardEntryRenderer key={entry.id} entry={entry} linkPrefix={'/move/'} />
          ))}
        </Row>
        {!result.last && (
          <Row>
            <Col style={{ textAlign: 'center', paddingTop: 32 }}>
              <Button color="link" disabled={loading} onClick={handleLoadMore}>
                {t('globals.loadMore', { defaultValue: 'Load More' })}
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
});
