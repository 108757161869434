/**
 * Created by katarinababic on 7.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import styled from '@emotion/styled';

export type LanguageTagProps = {
  language: string;
};

const Tag = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #edeef0;
  border-radius: 8px;
  padding: 12px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 16px;
`;

export const LanguageTag: React.FC<LanguageTagProps> = observer(({ language }) => {
  return <Tag>{language}</Tag>;
});
