/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ArticleEntry } from '../../../Model/Explore/ArticleEntry';
import { Col, Container, Row } from 'reactstrap';
import { ExploreContentViewVideoPlayer } from '../ExploreContentViewVideo/ExploreContentViewVideoPlayer';
import { ExploreContentViewArticleContent } from './ExploreContentViewArticleContent';
import { LanguageDisclaimer } from '../LanguageDisclaimer';

export type ExploreContentViewArticleProps = {
  entry: ArticleEntry;
};

export const ExploreContentViewArticle: React.FC<ExploreContentViewArticleProps> = observer(({ entry }) => {
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <ExploreContentViewVideoPlayer entry={entry} />
        </Col>
        <Col xs={12} md={5} xl={3}>
          <LanguageDisclaimer availableLanguages={entry.availableLanguages} />
        </Col>
        <Col xs={12}>
          <ExploreContentViewArticleContent entry={entry} />
        </Col>
      </Row>
    </Container>
  );
});
