/**
 * Created by neo on 01.03.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { OnlineMeetingEntry } from '../../Model/Explore/OnlineMeetingEntry';
import { HorizontalSlider } from '../../Components/HorizontalSlider';
import styled from '@emotion/styled';
import { useTrans } from '../../Store/System/LocalizationStore';
import { Col, Row } from 'reactstrap';
import { Button } from '../../Components/Button';
import { Link } from 'react-router-dom';
import { ExploreCardEntryRenderer } from '../../Components/ExploreEntryCard/ExploreCardEntryRenderer';
import { Translation } from '../../Components/Translation';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const IntroContainer = styled(Row)`
  margin-top: 32px;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 2rem;
  line-height: 2rem;
  margin-bottom: 16px;
`;

const Description = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1.2rem;
`;

export type UpcomingLiveTrainingsSectionProps = {};

export const UpcomingLiveTrainingsSection: React.FC<UpcomingLiveTrainingsSectionProps> = observer((props) => {
  const [upcoming, setUpcoming] = useState<OnlineMeetingEntry[]>([]);

  const { t } = useTrans();

  useEffect(() => {
    OnlineMeetingEntry.upcoming({ size: 10, categories: ['live_training'] }).then((res) => setUpcoming(res));
  }, []);

  return (
    <Container>
      <IntroContainer>
        <Col sm={12} md={6}>
          <Header>
            <Translation
              i18nKey="upcomingLiveTrainings.title"
              defaultValue={`<span style="color: #24dca4">Upcoming</span> live trainings`}
            />
          </Header>
          <Description>
            <Translation
              i18nKey="upcomingLiveTrainings.intro"
              defaultValue={`<b>Take your fitness to the next level!</b> Choose from a wide range of different trainings, work out with our professional coaches and let them help you become the fittest version of yourself.`}
            />
          </Description>
        </Col>
        <Col sm={12} md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button tag={Link} to="schedule" shadowed={true}>
            <i className="bi bi-calendar-event" style={{ marginRight: 8 }} />
            {t('upcomingLiveTrainings.introSeeAllScheduleButton', { defaultValue: 'SEE ALL SCHEDULE' })}
          </Button>
        </Col>
      </IntroContainer>
      <HorizontalSlider>
        {upcoming.map((entry) => (
          <ExploreCardEntryRenderer key={entry.id} entry={entry} linkPrefix="/move/" />
        ))}
      </HorizontalSlider>
    </Container>
  );
});
