/**
 * Created by katarinababic on 7.3.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Col, Row } from 'reactstrap';
import { useRootStore } from '../../Store/useRootStore';
import { LiveTag } from '../LiveTag';
import { LanguageTag } from '../LanguageTag';
import { TimeTag } from '../TimeTag';
import { useTrans } from '../../Store/System/LocalizationStore';
import { DateTag } from '../DateTag';
import { CountdownButton } from '../CountdownButton';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { EntryAuthor } from '../EntryAuthor';
import { Instructor } from '../../Model/Explore/Instructor';
import { MarkdownRenderer } from '../Markdown/MarkdownRenderer';
import { SubscribableEntry } from '../../Model/Explore/SubscribableEntry';

const Container = styled.div`
  border-radius: 20px 20px 0 0;
  flex-direction: column;
  display: flex;
  background-color: #edeef0;
  margin: 48px 0;
  padding: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 600px) {
    flex-direction: row;
    border-width: 0;
    border-radius: 0 20px 20px 0;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
  }
`;

const LeftCol = styled(Col)`
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: ${(p) => `url('${p.image}')`};
  background-position: center center !important;
  background-size: cover !important;

  @media (min-width: 600px) {
    flex-direction: row;
    border-width: 0;
    border-radius: 0;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
  }
`;

const RightCol = styled(Col)`
  display: flex;
  flex-direction: column;
  padding: 32px;
  max-width: 100%;
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const TopContentRow = styled.div`
  display: flex;
`;

const BottomContent = styled(Row)`
  @media (min-width: 1024px) {
    max-width: 60%;
  }
`;

const TagsContainer = styled(Row)`
  align-self: flex-end;

  @media (min-width: 600px) {
    align-self: flex-start;
  }
`;

const Time = styled.h4`
  font-size: 20px;
  font-weight: 500;
  color: #000000;
`;

const Title = styled.div`
  color: #3c3c3c;
  font-weight: 700;
  margin-top: 16px;

  @media (min-width: 600px) {
    font-size: 24px;
  }
`;

const Subtitle = styled.div`
  color: #3c3c3c;
  font-weight: 500;
  margin-top: 16px;
  font-size: 1.1rem;

  @media (min-width: 600px) {
  }
`;

const Description = styled.p`
  color: #3c3c3c;
  font-weight: 400;
  margin: 16px 0;
  text-align: left;
  font-size: 1.2rem;

  @media (min-width: 600px) {
  }
`;

const AuthorRow = styled.div`
  margin-top: 16px;
`;

export type ScheduleItemProps = {
  entry: SubscribableEntry;
  onClick?: (entry: SubscribableEntry) => void;
  currentStartDate?: Dayjs;
  linkPrefix?: string;
};

export const ScheduleItem: React.FC<ScheduleItemProps> = observer(
  ({ entry, onClick, currentStartDate, linkPrefix }) => {
    const { language, analytics } = useRootStore();
    const { t } = useTrans();
    const navigate = useNavigate();

    const image = entry.getImage(language.language)?.largeOrMediumOrSmallest;
    const nextMeetingDate = currentStartDate ? entry.nextDateWithinTimeRange(currentStartDate) : entry.nextDate;
    const nextMeetingEndDate = nextMeetingDate?.add(dayjs.duration(entry.duration));
    const canJoinMeeting = entry.canJoinMeeting;
    const host: Instructor | undefined = entry.hosts[0];
    const time = `${entry.startTime} - ${dayjs(nextMeetingEndDate).format('HH:mm')}`;
    const description = entry.getDescription(language.language);
    const displayDescription = description
      .replace(/[^\p{L}0-9,:;\-.?! ]/gu, '')
      .slice(0, 350)
      .split(' ')
      .slice(0, -1)
      .join(' ')
      .concat(' ...');

    const handleClickCta = React.useCallback(() => {
      navigate(`${linkPrefix}${entry.id}`);
    }, [entry, navigate, linkPrefix]);

    const handleClick = React.useCallback(() => {
      onClick && onClick(entry);
    }, [entry, onClick]);

    return (
      <Container onClick={handleClick}>
        <LeftCol image={image} xs={12} md={6}>
          <TopContent>
            <TopContentRow>
              <LiveTag entry={entry} />
            </TopContentRow>
          </TopContent>
          <TagsContainer>
            <Col xs="auto">
              <LanguageTag language={t(`globals.language.${entry.language}`, { defaultValue: entry.language })} />
              <TimeTag time={nextMeetingDate?.format('LT')} />
              <DateTag
                day={nextMeetingDate?.format('ddd')}
                month={nextMeetingDate?.format('MMM')}
                date={nextMeetingDate?.format('DD')}
              />
            </Col>
          </TagsContainer>
        </LeftCol>
        <RightCol xs={12} md={6}>
          <TopContent>
            <Time>{time}</Time>
            <Title>{entry.getName(language.language)}</Title>
            <Subtitle>{entry.getShortDescription(language.language)}</Subtitle>
            <Description>
              <MarkdownRenderer text={displayDescription} />
            </Description>
          </TopContent>
          <BottomContent>
            <CountdownButton
              block={true}
              style={{ marginTop: 16, marginBottom: 16 }}
              date={nextMeetingDate?.valueOf()}
              countdownDeltaSeconds={1800}
              countdownEndText={t('onlineMeeting.joinMeetingButton', {
                defaultValue: 'Join Now',
              })}
              onClick={handleClickCta}
              disabled={!canJoinMeeting}
            />
            {host && (
              <AuthorRow>
                <EntryAuthor host={host} darkMode={false} />
              </AuthorRow>
            )}
          </BottomContent>
        </RightCol>
      </Container>
    );
  },
);
