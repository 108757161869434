/**
 * Created by neo on 30.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ExploreCategory } from '../../../../Model/Explore/ExploreCategory';
import styled from '@emotion/styled';
import { useRootStore } from '../../../../Store/useRootStore';
import { ExploreEntry } from '../../../../Model/Explore/ExploreEntry';
import { ExploreEntryBuilder } from '../../../../Model/Explore/ExploreEntryBuilder';
import { Col, Row } from 'reactstrap';
import { ExploreCardEntryRenderer } from '../../../../Components/ExploreEntryCard/ExploreCardEntryRenderer';
import { SUPPORTED_TYPES } from '../../../../Utils/Constants';

const Container = styled.div``;

export type ExploreContentViewCategoryRendererProps = {
  category: ExploreCategory;
  linkPrefix?: string;
};

export const ExploreContentViewCategoryRenderer: React.FC<ExploreContentViewCategoryRendererProps> = observer(
  ({ category, linkPrefix }) => {
    const { language } = useRootStore();

    const [entries, setEntries] = useState<ExploreEntry[]>([]);

    const title = category.getName(language.language);
    const description = category.getDescription(language.language);

    useEffect(() => {
      ExploreEntryBuilder.find({
        rootCategories: [category.identifier],
        type: SUPPORTED_TYPES,
        size: 200,
      }).then((res) => setEntries(res));
    }, [category]);

    return (
      <React.Fragment>
        <h1 style={{ marginBottom: 32 }}>{title}</h1>
        <p>{description}</p>
        <Row>
          {entries.map((entry) => (
            <ExploreCardEntryRenderer key={entry.id} entry={entry} linkPrefix={linkPrefix} />
          ))}
        </Row>
      </React.Fragment>
    );
  },
);
