/**
 * Created by neo on 22.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ExploreContent } from '../../Model/Explore/ExploreContent';
import { ExploreCategory } from '../../Model/Explore/ExploreCategory';
import { ExploreCategoryRenderer, ExploreCategoryRendererProps } from './ExploreCategoryRenderer';

export type ExploreContentSwitchProps = Pick<ExploreCategoryRendererProps, 'linkPrefix'> & {
  content: ExploreContent;
  index: number;
};

export const ExploreContentSwitch: React.FC<ExploreContentSwitchProps> = observer(({ content, index, linkPrefix }) => {
  if (content instanceof ExploreCategory) {
    return <ExploreCategoryRenderer category={content} index={index} linkPrefix={linkPrefix} />;
  }

  return null;
});
