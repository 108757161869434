/**
 * Created by neo on 27.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { SubscribableEntry } from '../../Model/Explore/SubscribableEntry';
import { useRootStore } from '../../Store/useRootStore';
import { CarouselItemProps, Col } from 'reactstrap';
import styled from '@emotion/styled';
import { EntryAuthor } from '../EntryAuthor';
import { useNavigate } from 'react-router-dom';
import { useTrans } from '../../Store/System/LocalizationStore';
import { ChangeButtonsProps } from './ChangeButtons';
import { Instructor } from '../../Model/Explore/Instructor';
import { CountdownButton } from '../CountdownButton';
import { LiveTag } from '../LiveTag';
import { LanguageTag } from '../LanguageTag';
import { DateTag } from '../DateTag';
import { TimeTag } from '../TimeTag';

const CarouselContainer = styled.div<{ image?: string }>`
  border: 8px solid #f6f6f7;
  border-radius: 20px 20px 0 0;
  max-width: 100%;
  height: 360px;
  display: flex;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 13.68%, rgba(0, 0, 0, 0) 101.33%),
    ${(p) => `url('${p.image}')`};
  background-position: center 30% !important;
  background-size: cover !important;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 600px) {
    background: ${(p) => `url('${p.image}')`};
    height: 420px;
    border-width: 0;
  }

  @media (min-width: 1280px) {
    height: 500px;
  }
`;

const DateContainer = styled.div``;

const Title = styled.div`
  color: white;
  font-weight: 700;
  margin-top: 16px;

  @media (min-width: 600px) {
    font-size: 24px;
  }
`;

const LeftCol = styled(Col)`
  display: flex;
  flex-direction: column;
  padding: 16px;

  @media (min-width: 600px) {
    border-radius: 20px 0 0 0;
    background: linear-gradient(159.42deg, rgba(0, 0, 0, 0.85) 13.68%, rgba(0, 0, 0, 0) 101.33%);
  }

  @media (min-width: 1280px) {
    padding: 32px;
  }
`;

const RightCol = styled(Col)`
  padding: 16px;

  @media (min-width: 1280px) {
    padding: 32px;
  }
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const TopContentRow = styled.div`
  display: flex;
  align-items: center;
`;

const BottomContent = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const AuthorRow = styled.div`
  margin-top: 16px;
`;

export type UpcomingCarouselItemProps = Pick<CarouselItemProps, 'onExiting' | 'onExited'> &
  ChangeButtonsProps & {
    entry: SubscribableEntry;
    linkPrefix?: string;
  };

export const UpcomingCarouselItem: React.FC<UpcomingCarouselItemProps> = observer(
  ({ entry, onPrevious, onNext, linkPrefix = '' }) => {
    const { language, analytics } = useRootStore();
    const { t } = useTrans();

    const navigate = useNavigate();

    const nextMeetingDate = entry.nextDate;
    const image = entry.getImage(language.language)?.largeOrMediumOrSmallest;
    const host: Instructor | undefined = entry.hosts[0];

    const handleClick = React.useCallback(() => {
      navigate(entry.id);
    }, [entry, navigate]);

    const handleClickCta = React.useCallback(() => {
      navigate(`${linkPrefix}${entry.id}`);
    }, [linkPrefix, entry, navigate]);

    return (
      <CarouselContainer image={image} onClick={handleClick}>
        <LeftCol md={6} lg={4}>
          <TopContent>
            <TopContentRow>
              <LiveTag entry={entry} />
            </TopContentRow>
            <Title>{entry.getName(language.language)}</Title>
          </TopContent>
          <BottomContent>
            <CountdownButton
              className="d-none d-sm-block"
              style={{ marginBottom: 8 }}
              date={nextMeetingDate?.valueOf()}
              countdownDeltaSeconds={1800}
              countdownEndText={t('onlineMeeting.joinMeetingButton', {
                defaultValue: 'Join Now',
              })}
              onClick={handleClickCta}
            />
            <AuthorRow>{host && <EntryAuthor host={host} darkMode={true} />}</AuthorRow>
          </BottomContent>
        </LeftCol>
        <RightCol xs="auto">
          <DateContainer>
            <TimeTag time={nextMeetingDate?.format('LT')} />
            <DateTag
              day={nextMeetingDate?.format('ddd')}
              month={nextMeetingDate?.format('MMM')}
              date={nextMeetingDate?.format('DD')}
            />
            {!!entry.language && (
              <LanguageTag language={t(`globals.language.${entry.language}`, { defaultValue: entry.language })} />
            )}
          </DateContainer>
        </RightCol>
      </CarouselContainer>
    );
  },
);
