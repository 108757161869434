/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { OnlineMeetingEntry } from '../../../Model/Explore/OnlineMeetingEntry';
import styled from '@emotion/styled';
import { useRootStore } from '../../../Store/useRootStore';
import ReactPlayer from 'react-player';
import { logger } from '../../../Utils/logger';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 16px;
  background-size: cover !important;
  background-position: center 30% !important;
  background-repeat: no-repeat !important;
  height: 300px;
  border-radius: 16px 16px 0px 0px;

  @media (min-width: 600px) {
    height: 360px;
  }

  @media (min-width: 992px) {
    height: 480px;
  }

  @media (min-width: 1280px) {
    height: 560px;
  }
`;

const OverlayContainer = styled.div`
  position: absolute;
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #edeef0;
  border-radius: 8px;
  padding: 16px;
  font-weight: 700;
  text-align: center;
`;

const Month = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

const Day = styled.div`
  font-size: 32px;
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  video {
    object-fit: cover;
  }
`;

const VideoPlayer = styled(ReactPlayer)``;

export type ExploreContentViewOnlineMeetingTeaserProps = {
  entry: OnlineMeetingEntry;
};

export const ExploreContentViewOnlineMeetingTeaser: React.FC<ExploreContentViewOnlineMeetingTeaserProps> = observer(
  ({ entry }) => {
    const { language } = useRootStore();

    const video = entry.getVideo(language.language)?.url;
    const image = entry.getImage(language.language)?.largeOrMediumOrSmallest;
    const nextMeetingDate = entry.nextDate;

    return (
      <Container style={{ background: `url('${image}')` }}>
        {!!video && (
          <VideoContainer>
            <VideoPlayer
              playsinline={true}
              url={video}
              width="100%"
              height="100%"
              muted={true}
              controls={true}
              playing={true}
              loop={true}
              style={{ objectFit: 'cover' }}
            />
          </VideoContainer>
        )}
        {nextMeetingDate && (
          <OverlayContainer>
            <Overlay>{nextMeetingDate.format('LT')}</Overlay>
            <Overlay style={{ marginTop: 16 }}>
              <Month>{nextMeetingDate.format('ddd')}</Month>
              <Day>{nextMeetingDate.format('DD')}</Day>
              <Month>{nextMeetingDate.format('MMM')}</Month>
            </Overlay>
          </OverlayContainer>
        )}
      </Container>
    );
  },
);
