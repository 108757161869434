/**
 * Created by neo on 03.03.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { SubscribableEntry } from '../Model/Explore/SubscribableEntry';
import dayjs from 'dayjs';

const LiveText = styled.div`
  display: flex;
  color: white;
  font-size: 14px;
  line-height: 14px;
  background-color: #3c3c3c;
  padding: 8px;
  border-radius: 8px;
  font-weight: bold;

  @media (min-width: 1280px) {
    font-size: 16px;
    line-height: 16px;
  }
`;

const RedDot = styled.div`
  width: 16px;
  height: 16px;
  background-color: #dc4747;
  border-radius: 100%;
  margin-right: 8px;
`;

export type LiveTagProps = {
  entry: SubscribableEntry;
};

export const LiveTag: React.FC<LiveTagProps> = observer(({ entry }) => {
  const [isLive, setIsLive] = useState(false);

  useEffect(() => {
    const nextMeetingDate = entry.nextDate;
    const nextMeetingEndDate = nextMeetingDate?.add(dayjs.duration(entry.duration));

    const timeout = setInterval(() => {
      setIsLive(
        nextMeetingDate && nextMeetingEndDate
          ? !dayjs().isBefore(nextMeetingDate.subtract(5, 'minutes')) &&
              !dayjs().isAfter(nextMeetingEndDate.add(5, 'minutes'))
          : false,
      );
    }, 1000);

    return () => timeout && clearInterval(timeout);
  }, [entry]);

  return <LiveText>{isLive && <RedDot />} LIVE</LiveText>;
});
